/* importing fonts starts here */

@font-face {
	font-family: "Montserrat-Light";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-Light"),
		url("../fonts/Montserrat-Light.ttf") format("woff");
}

@font-face {
	font-family: "Montserrat-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-Regular"),
		url("../fonts/Montserrat-Regular.ttf") format("woff");
}

@font-face {
	font-family: "Montserrat-Medium";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-Medium"),
		url("../fonts/Montserrat-Medium.ttf") format("woff");
}

@font-face {
	font-family: "Montserrat-SemiBold";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-SemiBold"),
		url("../fonts/Montserrat-SemiBold.ttf") format("woff");
}

@font-face {
	font-family: "Montserrat-Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Montserrat-Bold"),
		url("../fonts/Montserrat-Bold.ttf") format("woff");
}

@font-face {
	font-family: "Poppins-Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Poppins-Bold"), url("../fonts/Poppins-Bold.ttf") format("woff");
}

@font-face {
	font-family: "Poppins-Regular";
	font-style: normal;
	font-weight: normal;
	src: local("Poppins-Regular"),
		url("../fonts/Poppins-Regular.ttf") format("woff");
}

/* importing fonts ends here */

/* common css starts */

/* .first_component {
    position: sticky;
    top: 0px;
    z-index: 4;
  } */
.pdarkHeader {
	position: relative;
}
.clr_grn {
	color: #01949a;
}

html {
	scroll-behavior: smooth;
}

.pointer_cursor {
	cursor: pointer;
}

.lh-01 {
	line-height: 01;
}
.ml4 {
	position: relative;
	font-weight: 900;
	font-size: 4.5em;
}
.ml4 .letters {
	position: absolute;
	margin: auto;
	left: 0;
	top: 0.3em;
	right: 0;
	opacity: 0;
}
.first_component .head-wrapper {
	display: inline-block;
}
.first_component .head-wrapper .we {
	font-family: "Montserrat-Light";
	font-size: 87px;
	color: #fff;
}
.first_component .good {
	font-family: "Montserrat-SemiBold";
	font-size: 57px;
	color: #fff;
	display: block;
	line-height: 40px;
}
.first_component .head-wrapper h1 {
	top: -144px;
	left: 164px;
	color: #01949a;
	font-family: "Montserrat-SemiBold";
	font-size: 87px;
}
.home-blog p.life_before_txt {
	font-family: "Montserrat-Medium";
	font-size: 15px;
	color: #000;
	text-align: left !important;
	margin: 0px;
	margin-top: 15px;
}
.home-blog .top_heading_px {
	font-family: "Montserrat-SemiBold";
	font-size: 30px;
	padding: 0px;
	text-align: left;
}
.home-blog p.text_main {
	font-family: "Montserrat-Light";
	text-align: left;
	font-size: 16px;
}
.home-blog p.text-top-group {
	font-family: "Montserrat-Medium";
	font-size: 15px;
	margin-top: 9px;
}
.home-blog p.text-top-group-heading {
	color: #000;
	font-family: "Montserrat-SemiBold";
	font-size: 24px;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	overflow: hidden;
	-webkit-line-clamp: 2;
}
.home-blog p.text-top-group-desc {
	font-family: "Montserrat-Light";
	font-size: 14px;
	margin-bottom: 30px;
}
.home-blog ul {
	display: flex;
	justify-content: space-between;
}
.home-blog ul li::marker {
	font-size: 22px;
	color: #01949a;
}
.home-blog ul li {
	font-size: 20px;
	color: #01949a;
	font-family: "Montserrat-SemiBold";
}
.home-blog marquee {
	margin-top: 30px;
}
.home-blog h2 {
	font-family: "Montserrat-SemiBold";
	font-size: 60px;
	text-decoration: underline;
	color: #000;
}

.home-blog h3 {
	font-family: "Montserrat-SemiBold";
	font-size: 35px;
	line-height: 1.5;
	color: #000;
}
.bg-dty {
	position: absolute;
	background: #02020299;
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	height: 131%;
}
.pi {
	padding: 100px 0px;
}
