section.oddevensection .img_box {
  display: flex;
}
section.oddevensection .img_box figure {
  flex: 1;
}
.OddEvenImagesContent {
  padding-bottom: 5rem;
}

.OddEvenImagesContent .heading {
  font-family: "Montserrat-Medium";
  font-size: 32px;
  padding-right: 4rem;
}

.OddEvenImagesContent .text {
  font-family: "Montserrat-Light";
  font-size: 16px;
}

.OddEvenImagesContent .listing_main_div {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #0000002b;
  align-items: center;
  margin-bottom: 1rem;
}

.OddEvenImagesContent .number {
  color: #01949a;
  font-size: 50px;
  margin-right: 2.5rem;
}

.OddEvenImagesContent .my-res-odd-even {
  margin: 5rem 0;
}
