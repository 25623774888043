/* full header css starts here */
.header_full {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 17px;
    padding: 1.5rem 0;
    font-family: "Montserrat-Light";
    /* font-family: ; */
}

.header {
    position: absolute;
    color: white;
    z-index: 5;
    width: 100%;
}

.header_full .header_links {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    width: 100%;
    color: white;
}

.header_full .header_links_black {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-self: center;
    width: 100%;
    color: black;
}

.header_full .header_links span {
    margin: 0 1rem;
}

.header_full .hdr_content_spilt {
    display: flex;
    flex-direction: column;
}

.header_full .hdr_content_spilt span{
   cursor: pointer;
}

/* full header css ends here */