.help_to_save .border_btm_heading {
    border-bottom: 4px solid black;
    width: 25%;
    margin: auto;
}

.help_to_save .heading_help_save {
    font-size: 45px;
    line-height: 1.3;
    font-family: "Montserrat-SemiBold";
    padding-top: 130px;
}

.help_to_save .heading_help_save_text {
    font-size: 16px;
    font-family: "Montserrat-Light";
    line-height: 1.3;
}

.help_to_save {
    padding: 4rem 0;
}