
.JoinCommunity{
    background-color:#01949A;
    padding: 3rem 0;
    color: white;
}

.JoinCommunity .text_heading{
    font-size: 50px;
    font-family: "Montserrat-SemiBold";
    line-height: 1.2;
}

.JoinCommunity .text_p{
    font-size: 16px;
    font-family: "Montserrat-Light";
}

.JoinCommunity .icon_main_div{
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 3rem;
}

.JoinCommunity .icon_div{
    margin: 0 0.5rem;
}