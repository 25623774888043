.HowStarted .HowStarted_content .img_box {
  padding: 30px 50px;
  position: relative;
  text-align: center;
  overflow: hidden;
}
.HowStarted .HowStarted_content .img_box .backDv {
  content: "";
  background: #01949a;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  left: 0;
  top: -30px;
  transition: 0.6s;
  /* max-height: 75%; */
}
.HowStarted .HowStarted_content {
  padding: 10rem 0;
}
.HowStarted .top_heading {
  font-size: 22px;
  font-family: "Montserrat-SemiBold";
  color: #0000009c;
}
.HowStarted .top_heading_main {
  font-size: 43px;
  font-family: "Montserrat-SemiBold";
  color: #000000;
  line-height: 1.3;
}

.HowStarted .text_main {
  font-size: 16px;
  font-family: "Montserrat-Light";
  color: #000000;
  line-height: 1.3;
}

.HowStarted .content_top_div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.HowStarted .img_adjst_page {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.HowStarted .img_text {
  font-family: "Montserrat-Light";
  font-size: 16px;
}

.HowStarted .txt_above_txt {
  font-size: 22px;
  font-family: "Montserrat-Medium";
}

.parallax_section {
  display: flex;
  min-height: 70vh;
  position: relative;
  /* z-index: 2; */
}

.parallax_section .section__img {
  position: relative;
  display: flex;
}
.parallax_section .section__img__inner {
  height: 100vh;
  position: fixed;
  width: 31%;
  top: 0;
  mix-blend-mode: multiply;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  right: 15%;
}
.parallax_section .section__body {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 100;
}
.parallax_section .section__text {
  padding: 0vw;
}

.parallax_section .section__img__inner {
  filter: blur(30px);
  opacity: 0;
  background-position: 50% 40%;
  transition: filter 0.6s ease, opacity 0.6s ease, background-position 0.8s ease;
}

.parallax_section.active .section__img__inner {
  opacity: 1;
  background-position: 50% 50%;
  filter: blur(0);
}
