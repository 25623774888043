.LeftImageTop {
  overflow-x: hidden;
}
.LeftImageTop .mb-getStarted {
  margin-bottom: 0;
  position: fixed;
  background-size: 32% !important;
  right: 34%;
  top: 0%;
  z-index: 0;
}
.top_sec.pos-rel .flx-main {
  padding-top: 103px;
}

.LeftImageTop .flx-item-left {
  flex: 0.5 1;
  position: relative;
}
.LeftImageTop .lft-pd {
  padding-right: 18%;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-left: 10%;
}

.LeftImageTop .txt {
  font-size: 16px;
  font-family: "Montserrat-Light";
}

.LeftImageTop .heading {
  font-size: 45px;
  font-family: "Montserrat-SemiBold";
}

.WhatWeAreAbout {
  /* padding-bottom: 10rem; */
}

.WhatWeAreAbout .div_content {
  width: 50%;
  margin: auto;
}

.WhatWeAreAbout .main_heading {
  font-size: 34px;
  text-align: left;
  font-family: "Montserrat-Medium";
}

.WhatWeAreAbout .what_we_div {
  display: inline-block;
}

.WhatWeAreAbout .text {
  font-size: 16px;
  font-family: "Montserrat-Light";
  text-align: left;
  margin-top: 2rem;
}

.WhatWeAreAbout .head {
  font-size: 24px;
  font-family: "Montserrat-Semibold";
  text-align: left;
  margin-top: 4.5rem;
}

.WhatWeAreAbout .people_head {
  font-family: "Montserrat-Medium";
  font-size: 34px;
  display: inline-block;
  text-align: left;
}

.WhatWeAreAbout .item_name {
  text-align: left;
  font-size: 18px;
  font-family: "Montserrat-Medium";
}

.WhatWeAreAbout .item_img {
  filter: grayscale(100%);
  width: 100%;
}

.WhatWeAreAbout .we_Are_company {
  font-family: "Montserrat-Medium";
  font-size: 40px;
  margin-bottom: 0;
  line-height: 1;
  margin-top: 7rem;
}

.WhatWeAreAbout .does_good {
  font-family: "Montserrat-SemiBold";
  font-size: 50px;
  margin-bottom: 12rem;
}

.WhatWeAreAbout .content_heading {
  font-size: 45px;
  font-family: "Montserrat-Medium";
  line-height: 1.2;
}

.WhatWeAreAbout .ourtxt_head {
  font-family: "Montserrat-Medium";
  font-size: 26px;
}

.WhatWeAreAbout .ourtxt_txt {
  font-family: "Montserrat-Light";
  font-size: 14px;
}

.WhatWeAreAbout .gry_simpleBox {
  background-color: #3e3e3e;
  color: white;
  width: 100%;
  padding: 2rem 2.5rem;
  margin: 2rem 0;
}
.WhatWeAreAbout .gry_simpleBox_txt {
  font-family: "Montserrat-Light";
  font-size: 16px;
}
.WhatWeAreAbout .gry_simpleBox_head {
  font-family: "Montserrat-Medium";
  font-size: 19px;
}
.WhatWeAreAbout .mb-our_impact {
  padding-bottom: 10rem;
  background: #fff;
  position: relative;
  padding-top: 5rem;
}
.custom_images_section {
  position: relative;
}
.Footer_Four_section {
  position: relative;
  z-index: 2;
}
@media (max-width: 1366px) {
  .WhatWeAreAbout .people_head {
    font-size: 24px;
    margin-left: 54px;
  }
  .WhatWeAreAbout p.we_Are_company {
    margin-left: 63px;
  }
  .WhatWeAreAbout p.does_good {
    margin-left: 63px;
  }
  .WhatWeAreAbout p.content_heading {
    font-size: 33px;
    margin-left: 84px;
  }
  .WhatWeAreAbout .main_heading {
    position: relative;
    left: 51px;
  }
}
