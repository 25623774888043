/* .WhyToGetInvolved */

.WhyToGetInvolved .heading {
  font-size: 36px;
  font-family: "Montserrat-Medium";
}

.WhyToGetInvolved .text {
  font-size: 16px;
  font-family: "Montserrat-Light";
}

.WhyToGetInvolved .number {
  font-size: 26px;
  font-family: "Montserrat-Medium";
  text-align: center;
}

.WhyToGetInvolved .gry_box {
  background-color: #e1e1e1;
  padding: 2.5rem 1.5rem 2rem 2.5rem;
}

.WhyToGetInvolved .benefits {
  border-bottom: 2px solid black;
  display: inline-block;
  font-size: 26px;
  font-family: "Montserrat-Medium";
}
.WhyToGetInvolved .mb-cus {
  margin-bottom: 6rem;
  padding-top: 0px;
  padding-bottom: 0px;
}
/* .WeWouldLike */

.WeWouldLike {
  padding: 12rem 0;
}

.WeWouldLike .inpt-fld {
  width: 100%;
  border: 0;
  font-size: 16px;
  font-family: "Montserrat-Light";
  border-bottom: 1px solid;
}

.WeWouldLike .inpt-fld:focus {
  outline: none;
}

.WeWouldLike .inpt-txtarea {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-family: "Montserrat-Light";
  resize: none;
  border: 0;
  border-bottom: 1px solid;
  padding-top: 1.5rem;
}

.WeWouldLike .inpt-txtarea:focus {
  outline: none;
}

.WeWouldLike .label {
  font-size: 16px;
  font-family: "Montserrat-Light";
  margin: 1.5rem 0;
}

.WeWouldLike .heading {
  line-height: 1.7;
  font-size: 40px;
  font-family: "Montserrat-Light";
}

.WeWouldLike .align-center {
  align-self: center;
}

.We_Empower .we_empower_heading {
  font-size: 45px;
  font-family: "Montserrat-Regular";
  line-height: 1.3;
  margin-bottom: 5rem;
}

.We_Empower .mb_we_power {
  margin-bottom: 9rem;
}

.We_Empower .mt-img {
  margin-top: 5rem;
}

.We_Empower .align-center {
  align-self: center;
}

.We_Empower .heading {
  font-family: "Montserrat-Medium";
  font-size: 32px;
  padding-right: 2rem;
}

.We_Empower .txt {
  font-family: "Montserrat-Light";
  font-size: 16px;
}

.We_Empower .heading_image {
  font-family: "Montserrat-Medium";
  font-size: 20px;
}

.stack {
  position: relative;
  margin-bottom: 10vh;
  z-index: 1;
}
.stack__card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: unset;
  width: 100%;
  border-radius: 20px;
  position: sticky;
  top: 40px;
  margin-bottom: 40px;
  font-size: 10rem;
}

.gry_box .ps {
  display: flex;
}
.gry_box .ps .kil1 {
  flex: 0 0 80%;
  align-self: center;
}
.gry_box .ps .kil2 {
  flex: 0 0 20%;
  align-self: center;
}
.gry_box .ps .kil3 {
  flex: 0 0 100%;
}

.oddevensection .stack__card .img-and-num-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  position: relative;
  top: 41px;
}

.box-content-wrapper {
  height: 300px;
  display: block;
  position: relative;
  top: -45px;
}

.color-violet {
  background-color: #7a4eab;
}
.color-indigo {
  background-color: #4332cf;
}
.color-blue {
  background-color: #2f8fed;
}
.color-green {
  background-color: #4dcf42;
}
.color-yellow {
  background-color: #faeb33;
}
.color-orange {
  background-color: #f19031;
}
.color-red {
  background-color: #f2293a;
}
