.LifeBelowWater .LifeBelowWater_content {
  padding-top: 10rem;
}
.blog-wrapper {
  height: 727px;
  overflow: scroll;
}
.blog-wrapper::-webkit-scrollbar {
  display: none;
}
.LifeBelowWater .top_heading {
  font-size: 43px;
  font-family: "Montserrat-SemiBold";
  text-align: center;
}

.top_heading_px {
  font-size: 38px;
  font-family: "Montserrat-SemiBold";
  text-align: center;
  padding: 0 2rem;
}

.LifeBelowWater .life_before_txt {
  font-size: 22px;
  font-family: "Montserrat-SemiBold";
  color: #0000009c;
}

.LifeBelowWater .life_before_txt {
  margin-top: 3rem;
}

.LifeBelowWater .text-top-group {
  font-size: 10px;
  margin-bottom: 0.5rem;
  font-family: "Montserrat-Medium";
}

.LifeBelowWater .text-top-group-heading {
  font-size: 20px;
  margin-bottom: 0rem;
  font-family: "Montserrat-Medium";
}

.LifeBelowWater .text-top-group-desc {
  font-size: 12px;
  margin-bottom: 0rem;
  font-family: "Montserrat-Medium";
}

.LifeBelowWater .img_grp_ppl {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.LifeBelowWater .crds_categories_sec {
  padding-top: 10rem;
}

.LifeBelowWater .mb-auto-lifebelow {
  margin-bottom: 6rem;
}
