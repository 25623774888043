.cards_section {
    padding-top: 6rem;
    padding-bottom: 3rem;
}

.cards_section .crd-bg-img {
    min-height: 450px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 80%;
    margin-left: auto;
}

.cards_section .fix_pagination{
    position: sticky;
    top: 10%;
}

.cards_section .brdr-btm-gry {
    border-top: 2px solid #70707061;
    margin-top: 2rem;
    margin-bottom: 0rem;
    padding-top: 1.5rem;
    width: 80%;
    margin-left: auto;
}

.cards_section .crd-bg-img:hover .hovr_cards_content {
    display: flex;
}

.cards_section .mt-card {
    margin-top: 4rem;
}

.cards_section .hovr_cards_content {
 
    display: none;
    background: linear-gradient(to top, rgb(0 0 0 / 88%), rgb(255 255 255 / 0%));
    min-height: 450px;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: end;
    color: white;
    padding-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.cards_section .card_heading {
    font-size: 22px;
    margin-bottom: 0.5rem;
    font-family: "Montserrat-Regular";

}

.cards_section .card_text {
    font-size: 16px;
}

.cards_section .card_bottom_heading {
    font-size: 24px;
    font-family: "Montserrat-Regular";

}

.cards_section .card_desc{
    font-family: "Montserrat-Regular";
    font-size: 14px;
}