.OurServices {
  overflow: hidden;
}

.OurServices .service_container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  position: relative;
  left: -1%;
  width: 101%;
}

.OurServices .service_block {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-end;
  padding: 2.5rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding-bottom: 7rem;
}

.OurServices .heading {
  font-size: 30px;
}

.OurServices .text {
  font-size: 16px;
  line-height: 1.3;
}
#timeline {
  display: flex;
  background-color: #031625;
}
#timeline:hover .tl-item {
  width: 23.3333%;
}

.tl-item {
  transform: translate3d(0, 0, 0);
  position: relative;
  width: 25%;
  height: 100vh;
  min-height: 600px;
  color: #fff;
  overflow: hidden;
  transition: width 0.5s ease;
}
.tl-item:before,
.tl-item:after {
  transform: translate3d(0, 0, 0);
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.tl-item:after {
  background: rgba(3, 22, 37, 0.85);
  opacity: 1;
  transition: opacity 0.5s ease;
}
.tl-item:before {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, black 75%);
  z-index: 1;
  opacity: 0;
  transform: translate3d(0, 0, 0) translateY(50%);
  transition: opacity 0.5s ease, transform 0.5s ease;
}
.tl-item:hover {
  width: 30% !important;
}
.tl-item:hover:after {
  opacity: 0;
}
.tl-item:hover:before {
  opacity: 1;
  transform: translate3d(0, 0, 0) translateY(0);
  transition: opacity 1s ease, transform 1s ease 0.25s;
}
.tl-item:hover .tl-content {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.75s ease 0.5s;
}
.tl-item:hover .tl-bg {
  filter: grayscale(0);
}

.tl-content {
  transform: translate3d(0, 0, 0) translateY(25px);
  position: relative;
  z-index: 1;
  text-align: center;
  margin: 0 1.618em;
  top: 55%;
  opacity: 0;
}
.tl-content h1 {
  font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial,
    sans-serif;
  text-transform: uppercase;
  color: #1779cf;
  font-size: 1.44rem;
  font-weight: normal;
}

.tl-year {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.tl-year p {
  font-family: "Pathway Gothic One", Helvetica Neue, Helvetica, Arial,
    sans-serif;
  font-size: 1.728rem;
  line-height: 0;
}

.tl-bg {
  transform: translate3d(0, 0, 0);
  position: absolute;
  background-image: url(https://placeimg.com/801/801/nature);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  transition: filter 0.5s ease;
  filter: grayscale(100%);
}
