body {
  scroll-behavior: smooth !important;
}
.OurApproach {
  /* height: 100vh; */
  /* overflow: hidden; */
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 0px;
  background-color: #fff;
  position: sticky;
  top: 0px;
  z-index: 99;
  /* padding-bottom: 250px; */
  transition: all 0.4s ease-in-out;
}
.img-wrapper {
  transition: all 0.4s ease-in-out;
}
.darkHeader {
  position: relative;
}
.slider-img-wrapper img {
  transition: all 0.4s ease-in-out;
}
.OurApproach .main_page_heading {
  font-size: 50px;
  border-bottom: 4px solid black;
  border-bottom: 4px solid black;
  display: inline-block;
  padding-bottom: 0.7rem;
  margin-bottom: 1.5rem;
  font-family: "Montserrat-SemiBold";
}

/* .OurApproach .brdr-btm-line{
    border-bottom: 4px solid black;
    width: 30%;
} */
.OurApproach .text {
  font-size: 16px;
  font-family: "Montserrat-Light";
}
.OurApproach p {
  font-family: "Montserrat-Light";
}
/* .OurApproach img.slide_img-1 {
  transform: translateY(130px);
} */
.OurApproach img.slide_img-1 {
  transition: all 0.9s linear;
  /* transform: translateY(130px); */
}

.OurApproach img.slide_img-2 {
  transition: all 0.9s linear;
  /* transform: translateY(130px); */
}
.OurApproach img.scroll-1-img {
  opacity: 0;
}
.OurApproach img.scroll-2-img {
  opacity: 0;
}
.OurApproach .sub_heading {
  font-size: 30px;
  border-bottom: 4px solid black;
  display: inline-block;
  padding-bottom: 0.7rem;
  margin-bottom: 1.5rem;
  font-family: "Montserrat-Medium";
}
.OurApproach .numbers-div {
  border-top: 1px solid lightgrey;
  display: flex;
  padding: 1rem;
  align-items: center;
}
.numbers-div p:first-child {
  font-size: 60px;
  color: #01949a;
  font-family: "Montserrat-Medium";
}
.numbers-div p:last-child {
  margin-left: 14px;
  font-size: 17px;
  font-family: "Montserrat-Light";
  color: #000000;
}
.all {
  animation: up-down linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: up-down linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-down linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-down linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-down linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}
.all2 {
  animation: up-downe linear 4s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: up-downe linear 4s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: up-downe linear 4s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -o-animation: up-downe linear 4s;
  -o-animation-iteration-count: infinite;
  -o-transform-origin: 50% 50%;
  -ms-animation: up-downe linear 4s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}
@keyframes up-down {
  0% {
    transform: translate(1px, 40px);
  }
  24% {
    transform: translate(1px, 50px);
  }
  50% {
    transform: translate(1px, 30px);
  }
  74% {
    transform: translate(1px, 40px);
  }
  100% {
    transform: translate(1px, 50px);
  }
}

@keyframes up-downe {
  0% {
    transform: translate(1px, -40px);
  }
  24% {
    transform: translate(1px, -50px);
  }
  50% {
    transform: translate(1px, -30px);
  }
  74% {
    transform: translate(1px, -40px);
  }
  100% {
    transform: translate(1px, -50px);
  }
}

@media (max-width: 1366px) {
  .pi {
    padding-bottom: 210px;
  }
}
