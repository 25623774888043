.CustomIamgeSection {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  color: white;
  padding-bottom: 5.5rem;
  background-attachment: fixed;
}
.CustomIamgeSection .brdr-btm-white {
  width: 20%;
  border-bottom: 4px solid white;
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.CustomIamgeSection .custom_main_layout {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 90vh;
}

.CustomIamgeSection .font_heading {
  font-size: 46px;
  line-height: 01;
  font-family: "Montserrat-SemiBold";
}

.CustomIamgeSection .font_p {
  font-size: 14px;
  font-family: "Montserrat-Light";
}

.CustomIamgeSection .font_learn_more {
  font-size: 14px;
  font-family: "Poppins-Regular";
}
