.videoBgPlayPause .videoBgPlayPause {
    min-height: 100vh;
    background-color: black;
}

.videoBgPlayPause .bgvideo_playpause {
    /* position: absolute; */
    z-index: -1;
}

.videoBgPlayPause .videoBgPlayPause_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    background: black;
    color: white;
}

.videoBgPlayPause #video_bgplaypause {
    width: 150%;
}

.videoBgPlayPause .bg-trans-video {
    background: linear-gradient(89deg, rgb(0 0 0) 0%, rgba(255, 255, 255, 0) 40%);
    z-index: 111111;
    width: 115%;
    position: absolute;
    height: 100%;
    top: 0px;
    cursor: pointer;
    box-shadow: 17px 3px 8px 12px black inset;
}

.videoBgPlayPause .heading {
    font-size: 50px;
}


.videoBgPlayPause .rotate_earth_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    background: linear-gradient(89deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0) 25%);
}

.videoBgPlayPause .text {
    font-size: 16px;
}

.videoBgPlayPause .brdr-btm-wht {
    border-bottom: 4px solid white;
    width: 65%;
}