.GetStartedTopSection {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: black;
  margin-bottom: 5rem;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.GetStartedTopSection .text {
  font-family: "Montserrat-Light";
  font-size: 16px;
}
.GetStartedTopSection .heading_light {
  font-family: "Montserrat-Regular";
  font-size: 50px;
}

.GetStartedTopSection .heading {
  font-family: "Montserrat-SemiBold";
  font-size: 50px;
}

.GetStartedTopSection .bt-get-started {
  background: #01949a;
  color: white;
  border-radius: 200px;
  padding: 0.6rem 1.8rem;
  border: 0;
  font-size: 16px;
}
