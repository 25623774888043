/* maaz work */

.blogCate-main .blog_links_page {
	justify-content: center;
}

.blogCate-main .blog_links_page p {
	align-items: center;
	place-content: center;
	padding: 0.6rem 1.15rem;
	background-color: #01949a;
	color: white;
	margin: 0.25rem;
	border-radius: 5px;
	font-size: 13px;
	cursor: pointer;
}

.blogCate-main .blog_links_page p:hover {
	background-color: #000000;
}

.blogCate-main .blog_links_page .activesss {
	background-color: #000000;
}

/* maaz work */

.blogCate-main .blogCate-inner {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow-x: hidden;
	/* padding-top: 145px; */
}

.blogCate-main .banner-content input {
	border-radius: 16px;
	padding: 7px 20px;
	border: unset;
	border: unset;
	width: 72%;
	background: transparent;
}
.blogCate-main .banner-content input :focus-visible {
	outline-offset: unset;
}
.blogCate-main .banner-content ::placeholder {
	color: #aaaaaa;
}
.blogCate-main .banner-content .input-container {
	background-color: #e6e6e6;
	height: 40px;
	width: 56%;
	border-radius: 20px;
}
.blogCate-main .banner-content .join {
	background-color: #01949a;
	padding: 8px 30px;
	border: unset;
	border-radius: 20px;
	position: relative;
	bottom: 38px;
	left: 239px;
	color: #ffff;
}

.blogCate-main .tabs-container {
	padding-top: 88px;
	padding-bottom: 0px;
}

.blogCate-main .tabs-container .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	/* border-color: #dee2e6 #dee2e6 #fff; */
	background-color: #01949a;
	padding: 8px 24px;
	border: unset;
	border-radius: 16px;
	color: #ffff !important;
}

.blogCate-main .tabs-container .nav-tabs {
	border-bottom: unset;
}

.blogCate-main .tabs-container .nav-tabs .nav-item {
	margin-right: 12px;
}

.blogCate-main .tabs-container .nav-tabs .nav-link {
	margin-bottom: -1px;
	border: 1px solid transparent;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
	color: #7a7a7a;
	border: 1px solid;
	border-radius: 16px;
}

.blogCate-main .banner-content {
	padding: 170px 37px;
}

.blogCate-main .mainTitle {
	color: #000000;
	font-size: 50px;
	font-family: Montserrat-Regular;
	margin: 0px;
	line-height: 1.5;
}
.blogCate-main .subTitle {
	color: #000000;
	font-size: 50px;
	font-family: Montserrat-SemiBold;
	margin: 0px;
	line-height: 60px;
}

.blogCate-main .subTitle2 {
	color: #000000;
	font-size: 50px;
	font-family: Montserrat-SemiBold;
	margin: 0px;
}

.blogCate-main .mainPara {
	color: #000000;
	font-size: 18px;
	font-family: Montserrat-Light;
	margin: 0px;
	margin-top: 5px;
}

.blogCate-main .bestPick {
	padding-top: 100px;
	padding-bottom: 45px;
}

.blogCate-main .bestPick .head {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.blogCate-main .bestPick .head p {
	color: #000000;
	font-size: 22px;
	font-family: Montserrat-SemiBold;
}
.blogCate-main .bestPick .head a {
	color: #01949a;
	font-size: 22px;
	font-family: Montserrat-SemiBold;
}
.blogCate-main .right-Container .title {
	color: #000000;
	font-size: 16px;
	font-family: Montserrat-Regular;
	text-align: center;
	margin-top: 16px;
}

.blogCate-main .right-Container {
	background-color: #e1e1e1;
}

.blogCate-main .right-Container .mainTitle {
	color: #000000;
	font-size: 18px;
	font-family: Montserrat-Medium;
	margin: 0px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
}
.blogCate-main .right-Container .mainPara {
	color: #000000;
	font-size: 12px;
	font-family: Montserrat-Light;
	margin-top: 5px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
}

.blogCate-main .started {
	text-align: center;
}

.blogCate-main .started .subTitle {
	color: #000000;
	font-size: 22px;
	font-family: Montserrat-SemiBold;
	margin-bottom: 12px;
}
.blogCate-main .started .mainTitle {
	color: #000000;
	font-size: 38px;
	font-family: Montserrat-SemiBold;
}
.blogCate-main .started .para {
	color: #000000;
	font-size: 12px;
	font-family: Montserrat-Light;
	padding: 0px 42px;
	margin-top: 12px;
}

.blogCate-main .blogs {
	padding-top: 58px;
	padding-bottom: 5px;
}

.blogCate-main .blogs .mainTitle {
	color: #000000;
	font-size: 20px;
	font-family: Montserrat-Medium;
	margin: 0px;
	line-height: initial;
}
.blogCate-main .blogs .mainPara {
	color: #000000;
	font-size: 11px;
	font-family: Montserrat-Light;
	margin-top: 5px;
}
.blogCate-main .blogs .para {
	color: #000000;
	font-size: 12px;
	font-family: Montserrat-Medium;
	margin: 0px;
}

.blogCate-main .ocean-sec {
	padding: 50px 0px;
}

.blogCate-main .ocean-inner {
	background-color: #e1e1e1;
	padding: 60px 72px;
}

.blogCate-main .ocean-inner .sm-p {
	color: #000000;
	font-size: 12px;
	font-family: Montserrat-Medium;
}
.blogCate-main .ocean-inner .mainTitle {
	color: #000000;
	font-size: 28px;
	font-family: Montserrat-Medium;
}
.blogCate-main .ocean-inner .para {
	color: #000000;
	font-size: 18px;
	font-family: Montserrat-Light;
	margin-top: 10px;
}

.blogCate-main .ocean-inner .read-btn {
	background-color: #01949a;
	padding: 5px 30px;
	border: unset;
	border-radius: 16px;
	color: #ffff;
}

.blogCate-main .ocean-sec .imgBox {
	position: absolute;
	left: -40px;
	bottom: 43px;
}

.blogCate-main .blog-vt {
	padding-top: 32px;
	padding-bottom: 60px;
}

.blogCate-main .blog-vt .sm-p {
	color: #000000;
	font-size: 12px;
	font-family: Montserrat-Medium;
	margin-top: 6px;
	margin-bottom: 3px;
}
.blogCate-main .blog-vt .mainTitle {
	color: #000000;
	font-size: 13px;
	font-family: Montserrat-Medium;
}
.blogCate-main .blog-vt .mainPara {
	color: #000000;
	font-size: 13px;
	font-family: Montserrat-Light;
	margin-top: 8px;
}

.blogCate-main .tabs-card {
}
.blogCate-main .tab-contMain {
	padding-top: 60px;
	padding-bottom: 40px;
}
.blogCate-main a {
	color: #01949a;
}

.blogCate-main .tabs-card .mainTitle {
	color: #000000;
	font-size: 24px;
	font-family: Montserrat-Medium;
	margin-top: 8px;
}
.blogCate-main .tabs-card .mainPara {
	color: #000000;
	font-size: 14px;
	font-family: Montserrat-Light;
	margin-top: 8px;
}
