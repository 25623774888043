.tooltip {
  position: relative;
}

.tooltip-text {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  left: 0;
  padding: 5px;
  position: absolute;
  top: 0;
}
.Community .Community_content .slider-img-wrapper img {
  transition: 0.8s;
  width: 0%;
  height: 330px;
  object-fit: cover;
}
.Community .Community_content .slider-img-wrapper img.fadein {
  width: 100%;
}
.Community .Community_content {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.po {
  position: sticky;
  top: 0px;
  z-index: 99;
  padding-bottom: 250px;
  transition: all 0.4s ease-in-out;
  padding: 150px 0px;
}
.Community .mt-comm {
  margin-top: 6rem;
}
.slider-img-wrapper img {
  transition: all 0.4s ease-in-out;
}
.pi {
  position: sticky;
  top: 0px;
  z-index: 99;
  padding-bottom: 250px;
  transition: all 0.4s ease-in-out;
  padding: 150px 0px;
}
.Community .brdr-btm-line {
  border-bottom: 4px solid black;
  width: 20%;
}
.darkHeader {
  position: relative;
}
.Community .heading {
  font-size: 40px;
  font-family: "Montserrat-Medium";
  color: #000000;
}

.Community .text {
  font-size: 14px;
  font-family: "Montserrat-Light";
}

.Community ul {
  /* list-style: none; */
}

/* .Community ul li::before {
  content: "\2022";
  color: #01949a;
  font-weight: bolder;
  display: inline-block;
  width: 1em;
  font-size: 22px;
  margin-left: -1em;
} */
.Community ul.ul_list_txt li::marker {
  color: #01949a;
  font-size: 23px;
}

/* Community page */

.CommunityPage .mb-community {
  margin-bottom: 80vh;
}
