/* space page css starts*/

.nature_page_one .space_content_heading {
    font-size: 20px;
    line-height: 1.3;
    font-family: "Montserrat-Medium";
}

.nature_page_one .space_content_p{
    font-size: 14px;
    line-height: 1.3;
    font-family: "Montserrat-Light";
}

.nature_page_one .align-center{
    align-self: center;
}

.nature_page_one .mt-cus-space{
    margin-top: 5rem;
}
.space_content ul#pills-tab {
    display: block;
}

.for-flex-and-align {
    display: flex;
    align-items: baseline;
}
.space_content .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #01949a;
    background-color: transparent;
    font-weight: 600;
}
.space_content .nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #000;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}
/* space page css ends */