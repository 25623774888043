.flx-main {
	display: flex;
	flex-direction: row;
}

.flx-item-right {
	flex: 1;
}

.flx-item-left {
	flex: 1;
}

.lft-pd {
	padding-left: 22%;
	display: flex;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	height: 100%;
}

.bt-get-started {
	background: #01949a;
	color: white;
	border-radius: 200px;
	padding: 0.6rem 1.8rem;
	border: 0;
	font-size: 16px;
}

.WhyToGetInvolvedPartnership .heading {
	font-size: 20px;
	font-family: "Montserrat-SemiBold";
	padding-right: 4rem;
}

.WhyToGetInvolvedPartnership .txt {
	font-size: 16px;
	font-family: "Montserrat-Light";
}

.WhyToGetInvolvedPartnership .main_heading {
	font-family: "Montserrat-Medium";
	font-size: 38px;
	margin-bottom: 3rem;
}

.WhyToGetInvolvedPartnership .main_heading_brdr {
	border-bottom: 4px solid black;
	font-size: 38px;
	font-family: "Montserrat-SemiBold";
	display: inline-block;
}

.WhyToGetInvolvedPartnership .my-6rem {
	margin-top: 6rem;
	margin-bottom: 4.5rem;
}

.Card_Partnership .my-card-r {
	margin: 5rem 0;
}

.Card_Partnership .heading {
	font-size: 36px;
	font-family: "Montserrat-Medium";
	padding-right: 5rem;
}

.Card_Partnership .text {
	font-size: 16px;
	font-family: "Montserrat-Light";
}

.Card_Partnership .align-center {
	align-self: center;
}
section.whygetinvovled
	~ section.whygetinvovled
	.Card_Partnership
	.section__img__inner {
	background-image: none;
}
.BenefitsPartnership {
	margin-bottom: 12rem;
}

.BenefitsPartnership .txt {
	font-size: 16px;
	font-family: "Montserrat-Light";
}

.BenefitsPartnership .heading_brdr {
	border-bottom: 4px solid black;
	font-size: 26px;
	font-family: "Montserrat-SemiBold";
	display: inline-block;
}

.BenefitsPartnership .gry_div {
	background-color: #e1e1e1;
	padding: 2rem;
}

.BenefitsPartnership .main_head {
	font-size: 36px;
	font-family: "Montserrat-SemiBold";
}

.BenefitsPartnership .mb-rr {
	margin-bottom: 4rem;
	margin-top: 5rem;
}

.whygetinvovled .benefacto_coin {
	font-size: 45px;
	font-family: "Montserrat-SemiBold";
	display: inline-block;
	border-bottom: 3px solid black;
}
.whygetinvovled .benefacto_coin {
	font-size: 45px;
	font-family: "Montserrat-SemiBold";
	display: inline-block;
	border-bottom: 3px solid black;
}
.whygetinvovled .benefacto_coin_p {
	font-size: 16px;
	font-family: "Montserrat-Light";
}

.RoadMap {
	padding-bottom: 4rem;
}

.RoadMap .card_roadmap {
	background-color: #ebebeb;
	color: black;
	width: 100%;
	padding: 2.5rem;
	margin: 2.5rem 0;
	border-radius: 10px;
}

.RoadMap .numbr {
	font-size: 80px;
	font-family: "Montserrat-SemiBold";
	position: relative;
	top: -24px;
	color: #01949a;
}

.RoadMap .heading {
	font-family: "Montserrat-Medium";
	font-size: 26px;
	text-align: left;
}

.RoadMap .txt {
	font-family: "Montserrat-Light";
	font-size: 14px;
	text-align: left;
}

.RoadMap .ul_pad_zero {
	padding-left: 0;
}

.RoadMap .ul_tick_round_white {
	background: url("../images/Crypto_Token/crypto_tick.png");
	background-position: 0;
	background-repeat: no-repeat;
	list-style: none;
	margin: 16px 0 0;
	padding-left: 35px;
	font-size: 14px;
	font-family: "Montserrat-Light";
	text-align: left;
}

.RoadMap .bt-grn-roadmap {
	background: white;
	border: 1px solid #01949a;
	border-radius: 50px;
	padding: 0.8rem 3rem;
	margin: 0 0.5rem;
	font-size: 18px;
}

.RoadMap .bt-grn-roadmap:focus,
.RoadMap .bt-grn-roadmap:hover {
	background: #01949a;
	color: white;
}

.RoadMap .road_map_head {
	font-family: "Montserrat-Medium";
	font-size: 36px;
}

.RoadMap .road_map_p {
	font-family: "Montserrat-Light";
	font-size: 14px;
}

html {
	line-height: 0;
}

.background {
	position: fixed;
	top: 0;
	left: 0;
	width: 50%;
	height: 100vh;
	z-index: 1;
}

.section {
	display: flex;
	min-height: 100vh;
	position: relative;
	z-index: 2;
}

.section__img {
	position: relative;
	display: flex;
}
.section__img__inner {
	height: 100vh;
	position: fixed;
	width: 31%;
	top: 0%;
	mix-blend-mode: multiply;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
}
.section__body {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 100;
}
.section__text {
	padding: 0vw;
}

.section__img__inner {
	filter: blur(30px);
	opacity: 0;
	background-position: 50% 40%;
	transition: filter 0.6s ease, opacity 0.6s ease, background-position 0.8s ease;
}

.section.active .section__img__inner {
	opacity: 1;
	background-position: 50% 50%;
	filter: blur(0);
}

h2 {
	font-size: 3vw;
	margin-bottom: 0.5em;
	margin-top: 0;
}
