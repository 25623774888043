/* footer 4square content starts here */
.footer_four {
	background-color: black;
	color: white;
	padding: 0;
	margin: 0;
	min-height: 100vh;
}

.footer_four .four_square_text {
	font-size: 36px;
	font-family: "Montserrat-Light";
	line-height: 01;
}

.footer_four .bt-learn-more {
	background: #01949a;
	border-radius: 60px;
	padding: 0.5rem 1.4rem;
	border: none;
	color: white;
	font-size: 14px;
	margin-top: 1.5rem;
	width: 160px;
	font-family: "Poppins-Regular";
}

.footer_four .text_area {
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100vh;
}

.footer_four .square_box_layout {
	min-height: 300px;
	border-left: 1px solid #ffffff1a;
	border-top: 1px solid #ffffff1a;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1.5rem;
	height: 66%;
}

.footer_four .lrn_mr {
	font-size: 16px;
	font-family: "Poppins-Regular";
}

.footer_four .square_green_txt {
	color: #01949a;
	font-size: 32px;
	font-family: "Montserrat-Medium";
}
/* footer 4square content starts here */

/* footer bottom content starts here */
.footer {
	background-image: url("../images/footer/footer_img.webp");
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: white;
	padding: 15rem 0 8rem 0;
	position: relative;
}

.footer .text_area {
	padding-left: 3rem;
}

.footer .bt-learn-more {
	background: #01949a;
	border-radius: 60px;
	padding: 0.5rem 2.4rem;
	border: none;
	color: white;
	font-size: 14px;
}

.footer .right_icon_learn_more {
	margin-left: 0.5rem;
	font-size: 26px;
	position: relative;
	top: 0px;
}

.footer .light_text_div {
	margin-top: 8rem;
}

.footer .sign_up_stay {
	font-family: "Montserrat-Light";
	font-size: 18px;
}

.footer .footer_pages_down_text {
	color: #ffffff5e;
	font-family: "Montserrat-Light";
}
.footer .footer_pages_down_text a {
	color: #ffffff5e;
}

.footer .footer_pages_text {
	font-size: 26px;
	font-family: "Montserrat-Medium";
	margin-bottom: 1.5rem;
}
.footer .footer_pages_text a {
	font-size: 26px;
	font-family: "Montserrat-Medium";
	margin-bottom: 1.5rem;
	color: #fff;
}

/* footer bottom content ends here */
