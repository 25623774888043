.AroundTheGlobeSlider{
    background-color: #E1E1E1;
    padding: 4rem 0;
}
.AroundTheGlobeSlider .slider_heading{
    font-size: 20px;
    font-family: "Montserrat-Medium";
}

.AroundTheGlobeSlider .slider_p{
    font-size: 14px;
    font-family: "Montserrat-Light";
}

.AroundTheGlobeSlider .around_globe_heading{
    font-size: 32px;
    font-family: "Montserrat-Medium";
}

.AroundTheGlobeSlider .Slider_top_custom_arrows{
    background-image: url("../images/life_below_water/arrow-right.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 25px;
    position: absolute;
    width: 35px;
    transform: rotate(180deg);
    z-index: 1;
    top: -12%;
    right: 8%;
    cursor: pointer;
}

.AroundTheGlobeSlider .Slider_top_custom_arrows-next{
    background-image: url("../images/life_below_water/arrow-right.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 25px;
    position: absolute;
    width: 35px;
    z-index: 1;
    top: -12%;
    cursor: pointer;
    right: 2.5%;
}