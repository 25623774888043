/* first home component starts */

.home_page .first_component {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
    /* background-image: linear-gradient(to right, #000000, #000000ad, #00000014); */
}

.home_page #video {
    width: 105vw;
    z-index: -1;
    position: absolute;
}

.home_page .home_left_text {
    width: 100%;
    max-width: 500px;
    background-image: url('../../assests/images/home/left_home.png');
    background-position: center;
    background-size: cover;
    height: 500px;
}

.home_page .left_home_div {
    padding-top: 25%;
}

.home_page .home_content_large {
    color: white;
    font-size: 65px;
    margin: 0;
    /* color: white; */
}

.home_page .home_content_small {
    color: white;
    font-size: 45px;
    margin: 0;
}

.home_page .pad-top-text {
    padding-top: 8rem;
    padding-left: 5rem;
}

.home_page .home_right_text {
    width: 115%;
    background-image: url('../../assests/images/home/left_home.png');
    background-position: right;
    background-size: cover;
    height: 100%;
    position: absolute;
    left: 69%;
    top: 5%;
}

/* first home component ends here */

/* fifth component starts */
.home_page .fifth_component {
    height: 100vh;
    width: 100vw;
    overflow-y: hidden;
    overflow-x: hidden;
}

/* fifth component ends here */

/* seventh component starts here*/
.SeventhComponent{
    overflow: hidden;
}

.SeventhComponent .sevent_component_content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: white;
    min-height: 100vh;
    padding-bottom: 7rem;
}

.SeventhComponent .heading {
    font-size: 50px;
}

.SeventhComponent .text {
    font-size: 16px;
}

.SeventhComponent .learn_more {
    font-size: 16px;
}

.SeventhComponent .brdr-btm-white{
    border-bottom: 4px solid white;
    width: 40%;
}

/* sevent component ends here */

/* tenth component starts */

.TenthComponent {
    background-image: url("../images/home/tenth_component.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: white;
}

.TenthComponent .green_div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    padding-bottom: 7rem;
}

.TenthComponent .heading {
    font-size: 18px;
}

.TenthComponent .text {
    font-size: 35px;
    margin-bottom: 0;
}

/* tenth component ends here */

/* twelfth component starts */
.TwelfthComponent {
    background-image: url("../images/home/green_pic.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: white;
    background-attachment: fixed;
}

.TwelfthComponent .green_div {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 100vh;
    padding-bottom: 7rem;
}

.TwelfthComponent .heading {
    font-size: 18px;
}

.TwelfthComponent .text {
    font-size: 35px;
    margin-bottom: 0;
}

/* twelfth component ends */