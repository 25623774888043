.home1 {
    overflow-x: hidden;
    min-height: 100vh;
}

.home1 #video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
}

.home1 .home_content_large {
    display: flex;
}

.home1 .dsply-none {
    display: none;
}

.home1 .visiblty-hdn {
    visibility: hidden;
    transition: all 1.5s;
}

.home1 .z-indx-1 {
    z-index: -2;
}

.home1 .video_no {
    background-image: none;
}

.home1 .fadeOut {
    opacity: 0;
    width: 0;
    height: 100px;
    transition: width 0.0s 0.0s, height 0.5s 0.5s, opacity 0.5s, bottom 3.5s;
    position: absolute;
    top: 20%;
    left: 44%;
}

.home1 .fadeIn {
    opacity: 1;
    position: static;
    width: auto;
    height: 100px;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s, bottom 3.5s, ;
}

.home1 .pad-top-text {
    position: relative;
}

.home2 {
    background: url('../images/home/manypowerplants.png');
    filter: grayscale(100%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    color: white;
    font-size: 64px;
    font-family: "Montserrat-SemiBold";
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 5rem;
}

.ReadOurArticle .brdr_btm_heading {
    font-size: 45px;
    font-family: "Montserrat-SemiBold";
    border-bottom: 4px solid black;
    display: inline-block;
}

.ReadOurArticle {
    padding-top: 8rem;
}

.ReadOurArticle .be_notified {
    font-size: 28px;
    font-family: "Montserrat-SemiBold";
}

.ReadOurArticle .marquee_text {
    font-size: 16px;
    font-family: "Montserrat-SemiBold";
    color: #01949A;
    margin-top: 1.5rem;
}

.ReadOurArticle .dots {
    margin: 0 3rem;
    font-size: 40px;
    position: 0;
    line-height: 0;
    position: relative;
    top: -2px;
}

.ReadOurArticle .stickyy {
    position: sticky;
    top: 5%;
}

.ReadOurArticle .category {
    font-size: 13px;
    font-family: "Montserrat-Medium";
    margin-bottom: 0.3rem;
    margin-top: 1rem;
}

.ReadOurArticle .heading {
    font-size: 24px;
    font-family: "Montserrat-SemiBold";
}

.ReadOurArticle .text {
    font-size: 16px;
    font-family: "Montserrat-Light";
}