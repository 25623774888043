.TermsAndCondition .TermsAndCondition_content {
    padding: 8rem 0;
}

.TermsAndCondition .policy_heading {
    font-size: 45px;
    font-family: "Montserrat-SemiBold";
    text-align: center;
    margin-bottom: 8rem;
}

.TermsAndCondition .bt-actv-tc {
    background: #01949A;
    color: white;
    font-size: 16px;
    border-radius: 60px;
    padding: 0.5rem 2rem;
    margin-right: 1rem;
}

.TermsAndCondition .bt-tc {
    background: white;
    color: #01949A;
    border: 1px solid #01949A;
    font-size: 16px;
    border-radius: 60px;
    padding: 0.5rem 2rem;
    margin-right: 1rem;
}

.TermsAndCondition .btn-light:focus {
    box-shadow: none;
}

.TermsAndCondition .tc_content p {
    font-size: 16px;
    font-family: "Poppins-Regular";
    margin-bottom: 2rem;
}