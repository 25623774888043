.SingleImageCompoenent {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0px;
  width: 100%;
  /* background-image:url(); */
}

.home_page .SingleImageCompoenent {
  filter: grayscale(100%);
  background-attachment: fixed;
}
.marquee-wrapper1 ul {
  display: flex;
  list-style: none;
  justify-content: space-between;
}

.marquee-wrapper1 ul li {
  color: #fff;
  font-family: "Montserrat-Light";
  font-size: 70px;
}
