/* Fixed Image Css Start Here */
.section {
	display: flex;
	min-height: 100vh;
	position: relative;
	z-index: 2;
}

.section__img {
	position: relative;
	display: flex;
}
.section__img__inner {
	height: 100vh;
	position: fixed;
	width: 31%;
	top: 0%;
	mix-blend-mode: multiply;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	right: 10%;
}
.section__body {
	display: flex;
	align-items: center;
	position: relative;
	z-index: 100;
}
.section__text {
	padding: 0vw;
}

.section__img__inner {
	filter: blur(30px);
	opacity: 0;
	background-position: 50% 40%;
	transition: filter 0.6s ease, opacity 0.6s ease, background-position 0.8s ease;
}

.section.active .section__img__inner {
	opacity: 1;
	background-position: 50% 50%;
	filter: blur(0);
}
/* Fixed Image Css End Here */

.spaceSustainibility-main .spaceSustainibility-inner {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-top: 145px;
}

.spaceSustainibility-main .banner-right-sec {
	padding: 274px 0px;
}
.spaceSustainibility-main .banner-left-sec {
	padding: 160px 0px;
}

.spaceSustainibility-main .mob-3 {
	margin-top: -469px;
}

.spaceSustainibility-main .main-title {
	color: #000000;
	font-size: 54px;
	font-family: Montserrat-SemiBold;
}
.spaceSustainibility-main .sec-title {
	color: #000000;
	font-size: 29px;
	font-family: Montserrat-SemiBold;
}
.spaceSustainibility-main .main-para {
	color: #000000;
	font-size: 14px;
	font-family: Montserrat-Light;
}

.spaceSustainibility-main .our-mission {
	text-align: center;
	margin-bottom: 50px;
}

.spaceSustainibility-main .mission-p1 {
	color: #000000;
	font-size: 36px;
	font-family: Montserrat-Light;
	margin: 0px;
}
.spaceSustainibility-main .mission-p2 {
	color: #000000;
	font-size: 36px;
	font-family: Montserrat-SemiBold;
	margin: 0px;
}

.spaceSustainibility-main .mission-p3 {
	color: #000000;
	font-size: 16px;
	font-family: Montserrat-Light;
	margin: 0px;
}

.spaceSustainibility-main .our-mission {
	padding: 80px 0px;
}

.spaceSustainibility-main .our-mission input {
	border-radius: 16px;
	padding: 7px 20px;
	border: unset;
	border: 1px solid;
}
.spaceSustainibility-main .our-mission .join {
	background-color: #01949a;
	padding: 5px 30px;
	border: unset;
	border-radius: 16px;
	/* position: absolute; */
	/* right: 440px; */
	position: relative;
	bottom: 38px;
	left: 201px;
	color: #ffff;
}

.our-mission input {
	width: 50%;
	position: relative;
}

.spaceSustainibility-main .cmb-card-p1 {
	color: #000000;
	font-size: 32px;
	font-family: Montserrat-SemiBold;
	margin: 0px;
	margin-top: 95px;
}
.spaceSustainibility-main .cmb-card-p2 {
	color: #000000;
	font-size: 18px;
	font-family: Montserrat-Light;
	margin: 0px;
}

.spaceSustainibility-main .cmb-main {
	padding: 60px 0px;
}

.spaceSustainibility-main .sm-card {
	padding: 80px 0px;
}
.spaceSustainibility-main .sm-card .para1 {
	color: #000000;
	font-size: 40px;
	font-family: Montserrat-SemiBold;
	margin: 0px;
}
.spaceSustainibility-main .sm-card .para2 {
	color: #000000;
	font-size: 18px;
	font-family: Montserrat-Light;
	margin: 0px;
}

.spaceSustainibility-main .sm-card .right-tags {
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
		rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	padding: 20px 20px 10px 20px;
}
.spaceSustainibility-main .sm-card .pos {
	margin-top: 82px;
}
.spaceSustainibility-main .sm-card .neg {
	margin-top: -48px;
}
.spaceSustainibility-main .sm-card .right-tags .cardTitle {
	color: #000000;
	font-size: 40px;
	font-family: Montserrat-Regular;
	margin: 0px;
}
.spaceSustainibility-main .sm-card .right-tags .cardPara {
	color: #000000;
	font-size: 14px;
	font-family: Montserrat-Light;
	margin: 0px;
}

.spaceSustainibility-main .opp-email {
	background-color: #01949a;
	padding: 70px 70px;
	text-align: center;
	color: #ffff;
}
.spaceSustainibility-main .opp-email .mainTitle {
	color: #ffff;
	font-size: 40px;
	font-family: Montserrat-SemiBold;
	margin: 0px;
}
.spaceSustainibility-main .opp-email .subTitle {
	color: #ffff;
	font-size: 30px;
	font-family: Montserrat-regular;
	margin: 0px;
}

.spaceSustainibility-main .opp-email input {
	border-radius: 16px;
	padding: 8px 20px;
	border: unset;
}
.spaceSustainibility-main .opp-email .join {
	background-color: #01949a;
	padding: 5px 20px;
	border: unset;
	border-radius: 16px;
	/* position: absolute; */
	/* right: 440px; */
	position: relative;
	bottom: 38px;
	left: 192px;
	color: #ffff;
}

.opp-email input {
	width: 50%;
	position: relative;
}
