.faqs .faq_banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 6rem; */
}

.faqs .faqs_heading {
    font-size: 45px;
    font-family: "Montserrat-SemiBold";
    padding: 15rem 0;
}

.faqs .bt-actv-tc {
    background: #01949A;
    color: white;
    font-size: 16px;
    border-radius: 60px;
    padding: 0.6rem 1.5rem;
    margin-right: 1rem;
}

.faqs .bt-tc {
    background: white;
    color: #9A9A9A;
    border: 1px solid #9A9A9A;
    font-size: 16px;
    border-radius: 60px;
    padding: 0.6rem 1.5rem;
    margin-right: 1rem;
}

.faqs .btn-light:focus {
    box-shadow: none;
}


/* //pasted css */

.faqs .accordian_colapse {
    background-color: white;
    color: black;
    border-bottom: 1px solid;
    border-radius: 0px !important;
    padding: 0rem 2.5rem 0rem 2.5rem;
    border-bottom: 2px solid #00000026;
}

.faqs .accordian_colapse_bgwht {
    background-color: transparent;
    color: black;
    border-radius: 0px !important;
    border: unset;
    border-bottom: 2px solid #0d0d0d33;
    padding: 0.5rem 2.5rem 0.5rem 2.5rem;
}

.faqs .accordian_all {
    background-color: transparent !important;
    box-shadow: unset;
    color: white;
    margin-bottom: 0rem;
}

.faqs .accordian_allaccordian_all {
    color: black;
    margin-bottom: 0rem;
    box-shadow: unset;
}

.faqs .faq_heading {
    font-size: 17px;
    font-family: "Montserrat-Semibold";
}

.faqs .faq_desc {
    font-size: 15px;
    color: black;
    font-family: "Montserrat-Light";
}

.faqs .faq_mainheading{
    text-align: center;
    font-size: 40px;
    font-family: "Montserrat-SemiBold";
}

.faqs .brdr-btm-lght{
    width: 100%;
    border-bottom: 2px solid #00000026;
    margin: 3.5rem 0 3.5rem;
}

.faqs .faq_content{
    padding-bottom: 5rem;
}