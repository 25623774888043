.savenature_component{
    overflow-x: clip;
    overflow-y: hidden;
    height: 100vh;
}

.savenature_component .first_component {
    height: 100vh;
    width: 100vw;
    overflow-y: auto;
    overflow-x: hidden;
    background-image: linear-gradient(to right, #000000 ,#000000ad , #00000014);
}

.savenature_component #video{
    width: 100%;
    z-index: -1;
    min-height: 100vh;
    position: absolute;
}

.savenature_component .home_left_text{
    width: 100%;
    /* max-width: 500px ; */
    /* background-image: url('../../assests/images/home/left_home.png'); */
    background-position: center;
    background-size: contain;
    min-height: 500px;
}

.savenature_component .left_home_div{
    padding-top: 8rem;
}

.savenature_component .home_content_large{
    color: white;
    font-size: 45px;
    line-height: 1.3;
    margin: 0;
    font-family: "Montserrat-SemiBold";
    /* color: white; */
}

.savenature_component .home_content_small{
    color: white;
    font-size: 14px;
    margin: 0;
    font-family: "Montserrat-Light";
}

.savenature_component .pad-top-text{
    padding-top: 8rem;
    padding-left: 5rem;
}

.savenature_component .home_right_text{
    width: 125%;
    /* background-image: url('../../assests/images/home/left_home.png'); */
    background-position: right;
    background-size: cover;
    background-position: left;
    height: 125%;
    position: absolute;
    left: 58%;
    top: -10%;
}